<template>
  <div>
    <el-form style="padding: 20px 20px 0" :model="form" ref="form" inline>
      <el-form-item label="UCID">
        <el-input v-model="form.user_ucid" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="手机">
        <el-input v-model="form.mobile" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="邮箱">
        <el-input v-model="form.email" placeholder="请输入" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onQuery">查询</el-button>
        <el-button type="info" @click="onClear">重置</el-button>
      </el-form-item>
    </el-form>

    <div style="padding: 0 20px;">
      <el-table :data="list" border stripe>
        <el-table-column label="UCID" prop="user_id"></el-table-column>
        <el-table-column label="手机号" prop="uc_mobile"> </el-table-column>
        <el-table-column label="邮箱" prop="uc_email"> </el-table-column>
        <el-table-column label="绑定姓名" prop="name"> </el-table-column>
        <el-table-column label="绑定手机" prop="mobile"></el-table-column>
        <el-table-column label="身份证" prop="id_card_no"></el-table-column>
        <el-table-column label="绑定卡号" prop="bank_account"></el-table-column>
        <el-table-column label="申请绑定时间" prop="created_at">
          <template slot-scope="scope">
            <span>{{ scope.row.created_at | dateFormater }}</span>
          </template>
        </el-table-column>
        <el-table-column label="绑定状态" prop="status"></el-table-column>
        <el-table-column label="绑定渠道" prop="channel_id"> </el-table-column>
        <el-table-column label="商户号" prop="bind_card_id"></el-table-column>
        <el-table-column label="备注" prop="back"></el-table-column>
      </el-table>
      <div style="padding: 20px 0; text-align: right;">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserOpeningAccount } from "@/api/nft.js";
import dayjs from "dayjs";

export default {
  data() {
    return {
      form: {},
      list: [],
      total: 0,
      pageSize: 20,
      page: 1,
    };
  },
  filters: {
    dateFormater: function(value) {
      return dayjs(+value * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    async fetchList() {
      try {
        const { data } = await getUserOpeningAccount({
          page: this.page,
          page_size: this.pageSize,
          ...this.form,
        });
        const { total = 0, items } = data?.data;
        this.list = [...(items || [])];
        this.total = total;
      } catch (error) {}
    },
    onQuery() {
      this.page = 1;
      this.fetchList();
    },
    onClear() {
      this.form = {};
      this.page = 1;
      this.pageSize = 20;
      this.fetchList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.fetchList();
    },
    handleCurrentChange(page) {
      this.page = page;
      this.fetchList();
    },
  },
};
</script>

<style lang="less" scoped></style>
